import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const SnsSection = () => {
  return (
    <>
      {" "}
      <section id="sns_sec" className="sec_padding_base">
        <div className="inner">
          <div className="title_box m-auto">
            <StaticImage
              src="../../../images/top/title_sns.png"
              alt=""
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="600"
              data-sal-easing="ease"
            />
          </div>
          <ul className="sns_list_box m-auto">
            <li className="item">
              <a href="https://www.facebook.com/myahklab/" target="_blank">
                <StaticImage
                  src="../../../images/top/icon_facebook.png"
                  alt="FacebookIcon"
                />
              </a>
            </li>
            <li className="item">
              <a href="https://twitter.com/mya_hk_lab" target="_blank">
                <StaticImage
                  src="../../../images/top/icon_twitter.png"
                  alt="TwitterIcon"
                />
              </a>
            </li>
            <li className="item">
              <a
                href="https://www.instagram.com/miyako_winterfes/"
                target="_blank"
              >
                <StaticImage
                  src="../../../images/top/icon_instagram.png"
                  alt="InstagramIcon"
                />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
