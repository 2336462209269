import React from "react";
import DummyLogo from "../../images/sponsor/sp-dummy.png";
import jta from "../../images/sponsor/logo_jta.png";
import locus from "../../images/sponsor/logo_locus.png";
import islandex from "../../images/sponsor/logo_islandex.png";
import rescape from "../../images/sponsor/logo_rescape.png";
import bethelight from "../../images/sponsor/logo_bethelight.png";
import yoshimoto from "../../images/sponsor/logo_yoshimoto.png";
import miyako from "../../images/sponsor/logo_miyako.png";
import izumi from "../../images/sponsor/logo_izumi.png";
import mediskin from "../../images/sponsor/logo_mediskin.png";
import sigma from "../../images/sponsor/logo_sigma.png";
import ripple from "../../images/sponsor/logo_ripple.png";
import oceans from "../../images/sponsor/logo_oceans.png";
import rugu from "../../images/sponsor/logo_rugu.png";
import serulean from "../../images/sponsor/logo_serulean.png";
import hamamoto from "../../images/sponsor/logo_hamamoto.png";
import aozora from "../../images/sponsor/logo_aozora.png";
import tomori from "../../images/sponsor/logo_tomori.png";
import unimat from "../../images/sponsor/logo_unimat.png";
import takken from "../../images/sponsor/logo_takken.png";
import usagiya from "../../images/sponsor/logo_usagiya.png";
import nikken from "../../images/sponsor/logo_nikken.png";
import islandworks from "../../images/sponsor/logo_islandworks.png";
import nk from "../../images/sponsor/logo_nk.png";
import tomishima from "../../images/sponsor/logo_tomishima.png";
import daishin from "../../images/sponsor/logo_daishin.png";
import ewave from "../../images/sponsor/logo_ewave.png";
import animal from "../../images/sponsor/logo_animal.png";
import myahk from "../../images/sponsor/logo_myahk.png";
import nics from "../../images/sponsor/logo_necs.png";
import polipoli from "../../images/sponsor/logo_polipoli.png";
import nkyan from "../../images/sponsor/logo_nkyan.png";
import r from "../../images/sponsor/logo_r.png";
import zeroko from "../../images/sponsor/logo_zeroko.png";
import root from "../../images/sponsor/logo_root.png";
import nem from "../../images/sponsor/logo_nem.png";
import sigma_big from "../../images/sponsor/logo_sigma_big.png";
import logical from "../../images/sponsor/logo_logical.png";
import nakao from "../../images/sponsor/logo_nakao.png";
import openingline from "../../images/sponsor/logo_openingline.png";

const Sponsor2 = () => {
  return (
    <div>
      <section id="modal_sec">
        <div className="title_box m-auto">
          <span>第1回 宮古島冬まつり</span>
          <br />
          ご協賛企業様
        </div>
        <div className="logo_part">
          <ul className="list_box full_size">
            <p className="subtitle">特別協賛</p>
            <li className="item">
              <img src={jta} alt="" />
              <p>日本トランスオーシャン航空株式会社</p>
            </li>
          </ul>

          <p className="subtitle">協賛企業</p>
          <ul className="list_box full_size middle">
            <li className="item">
              <img src={sigma_big} alt="" />
              <p>株式会社シグマ</p>
            </li>
            <li className="item">
              <img src={logical} alt="" />
              <p>株式会社ロジカル</p>
            </li>
          </ul>
          <ul className="list_box half_size">
            <li className="item">
              <img src={islandworks} alt="" />
              <p>有限会社アイランドワークス</p>
            </li>
            <li className="item">
              <img src={aozora} alt="" />
              <p>株式会社 青空</p>
            </li>
            <li className="item">
              <img src={nakao} alt="" />
              <p>焼肉なかお</p>
            </li>
            <li className="item">
              <img src={unimat} alt="" />
              <p>南西楽園リゾート（株式会社ユニマットプレシャス）</p>
            </li>
            <li className="item">
              <img src={openingline} alt="" />
              <p>株式会社 Opening Line</p>
            </li>
            <li className="item">
              <img src={ewave} alt="" />
              <p>E-WAVE（イーウェイブ）</p>
            </li>
          </ul>
          <p className="subtitle">特別サポーター</p>
          <ul className="list_box full_size ">
            <li className="item">
              <img src={nem} alt="" />
              <p>NEM.io財団</p>
            </li>
          </ul>
          {/* <p className="subtitle">協力企業・団体</p>
          <ul className="list_box half_size">
            <li className="item">
              <img src={animal} alt="" />
              <p>宮古島SAVE THE ANIMALS</p>
            </li>
            <li className="item">
              <img src={myahk} alt="" />
              <p>ミャークヌーマ宮古馬の会</p>
            </li>
            <li className="item">
              <img src={nics} alt="" />
              <p>エヌアイシーソフト</p>
            </li>
            <li className="item">
              <img src={polipoli} alt="" />
              <p>株式会社PoliPoli</p>
            </li>
            <li className="item">
              <img src={nkyan} alt="" />
              <p>んきゃーん塾</p>
            </li>
          </ul> */}
          <ul className="list_box full_size">
            <p className="subtitle">後援</p>
            <li className="item">
              <img src={miyako} alt="" />
              <p>宮古島市</p>
            </li>
          </ul>
          <p className="subtitle">全協賛企業様、協賛者様一覧（順不同）</p>
          <p className="all_sponser">
            日本トランスオーシャン航空株式会社、株式会社シグマ、NEM.io財団、株式会社ロジカル、オーシャンズリゾート宮古島、株式会社
            Opening Line、焼肉なかお、SHIGIRA RESORT、
            青空パーラー、ふわふわカンパニー、有限会社アイランドワークス、 HOTEL
            LOCUS、Riccoジェラート、玄設計事務所、海の居酒屋次男、
            古謝そば屋、沖之光酒造、
            とんかつ一番、南西楽園リゾート（株式会社ユニマットプレシャス）、
            伊佐設計事務所、新城設計室、日建株式会社、
            農事組合法人まるごと宮古島、富士製菓製パン、Bernie’s Dining、Hair
            Care ＆ Design GROWN、Bar ペンギン ここだけの話、Bar Daisy &
            HANA、MATO的エンタープライズ株式会社、株式会社翔邦建設工業、
            有限会社宮通企画、株式会社まるちく、串カツ錦、株式会社ShumMaKe、
            Handmade Jewelry D E S L I E、BISTROT PIERROT、株式会社はまもと、
            郷家、岡村歯科、医院もりお歯科、ドクターゴン診療所、
            琉球居酒屋さんご家
            {/* 日本トランスオーシャン航空株式会社、株式会社シグマ、NEM.io財団、株式会社ロジカル、オーシャンズリゾート宮古島、株式会社
            Opening Line、焼肉なかお、SHIGIRA RESORT、南西楽園リゾート（株式会社ユニマットプレシャス）、
            青空パーラー、ふわふわカンパニー、有限会社アイランドワークス、 HOTEL
            LOCUS、Riccoジェラート、玄設計事務所、海の居酒屋次男、古謝そば屋、
            沖之光酒造、とんかつ一番、伊佐設計事務所、新城設計室、日建株式会社、
            富士製菓製パン、Bernie’s Dining、Hair Care ＆ Design GROWN、Bar
            ペンギンここだけの話、Bar Daisy & HANA
            MATOエンタープライズ株式会社、
            株式会社翔邦建設工業、有限会社宮通企画、株式会社まるちく、もりお歯科、
            株式会社大進興業、フクギ保険事務所、株式会社オキジム、ダグズバーガー、
            株式会社いさむ自動車、みやちゃん食堂、
            ファッションハウススパイラルⅡ、宮古毎日新聞、勝工業、やまと商事、
            有限会社 吉田産業、ホテルニュー丸勝、宮古ルナ・ビルメンテナンス
            フォトスタジオACT-1、
            ボックリーのチョッキ、あかがーら、八千代バス、丸秀、千代田開発、
            ナカソネアルミ、ニューサウスウインド、三樹開発、朝日建設工業、
            酒処あずみの、アートアベニュー、JA沖縄宮古地区本部、SHIMKARAナカシマ、
            有限会社きらら、東和オート、TEPPAN Minowa、アトリエkoa、マルキヨ味噌 */}
            <br />
            【後援】宮古島市、宮古島市教育委員会、宮古島商工会議所、宮古島観光協会、一般社団法人宮古青年会議所、{" "}
          </p>
          <p className="subtitle">
            クラウドファンディングでご支援いただいた方々
          </p>
          <p className="all_sponser">
            SachihoNohara、garasaya、岡村善一Ryuuuu、koji
            shimosato、trulyjuli、sawaeri、paikaji1 |
            sasarukozee、coopees、raito
            nikadori、donguriknyacki、Hiroking08、turbine432、ksawada02 |
            hanejika、tsuyamildnagai
          </p>
        </div>
      </section>
    </div>
  );
};

export default Sponsor2;
