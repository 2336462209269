import React from "react";
import Header from "../components/layout/Header";
import ProjectActivity from "../images/top/pcoject_activity.jpg";
import past_activity1 from "../images/activity/past_activity1.jpg";
import past_activity2 from "../images/activity/past_activity2.jpg";
import past_activity3 from "../images/activity/past_activity3.jpg";
// import now_activity1 from "../images/activity/now_activity1.jpg";
import now_activity1 from "../images/activity/now_activity_1.jpg";
import now_activity2 from "../images/activity/now_activity2.jpg";
import now_activity3 from "../images/activity/now_activity_3.jpg";
// import now_activity3 from "../images/activity/now_activity3.jpg";
import { Link } from "gatsby";
import Footer from "../components/layout/Footer";

const activity = () => {
  return (
    <div>
      <Header
        ogpBlogImage={ProjectActivity}
        ogpBlogTitle="学生アクティビティ制作"
        ogpBlogDesc="アイデアを形に！オリジナルアクティビティを制作！"
      />
      <div id="blog_detail">
        <div className="inner">
          <h1 className="blog_title">学生アクティビティ制作</h1>
          <img
            src={ProjectActivity}
            alt="学生アクティビティ制作"
            className="eyecatch"
          />
          <div className="blog_content project_page">
            <h2 className="niri_title">
              アイデアを形に！オリジナルアクティビティを作ろう
            </h2>
            <h2>学生アクティビティとは？</h2>
            <p>
              <strong>
                宮古島の小学生から高校生まで幅広い世代が協力して、自分達で企画を考える取り組み。
              </strong>
              みんなで考えたアイディアを冬まつり当日、アリーナブースとして出展します！
            </p>

            <p>
              ・ゼロから何かを生み出す
              <br />
              ・仲間と1つのものをつくりあげる
              <br />
              ・学年の違う人や社会人と関わる
              <br />
              ・たくさんの人に自分達のアイディアを見てもらう
            </p>
            <p>これら全ての経験ができるのは冬まつりならでは！</p>

            <p>
              過去には企業とコラボしてIT技術を使ったゲームを作ったり、目隠しチャンバラ・絵心対決など興味深い取り組みがたくさん。
            </p>
            <img src={past_activity1} alt="学生アクティビティ" />
            <img src={past_activity2} alt="学生アクティビティ" />
            <img src={past_activity3} alt="学生アクティビティ" />
            <em>過去のイベントの様子</em>

            <p>
              現在は第3回目の冬まつりに向け、毎週日曜日、みんなで集まってアイディアを練っています！
            </p>
            <img src={now_activity1} alt="学生アクティビティ" />
            <img src={now_activity2} alt="学生アクティビティ" />
            <img src={now_activity3} alt="学生アクティビティ" />
            <em>ワークショップの様子</em>

            <p>
              学生ならではの柔軟な思考で、大人も子どもも一緒になって楽しめる、新しく企画を作っていきます！
            </p>
          </div>
        </div>
        <div className="prof_sec">
          <div className="inner">
            <h2>企画の様子はSNSにて発信中！</h2>
            <p className="niri_sns">
              <a
                href="https://www.instagram.com/miyako_winterfes/?r=nametag"
                target="_blank"
              >
                宮古島冬まつりInstagram
              </a>
            </p>

            <div className="button_part">
              <Link to={`/`}>
                <div className="base_button blog_detail_button">
                  <a href="">トップに戻る</a>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default activity;
