import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import TitleGuest from "../../../images/top/title_guest.png";
import GuestImage from "../../../images/top/guest_image.png";
import niri from "../../../images/niri/niri_image2.jpg";
import yaasuu1 from "../../../images/top/guest/yaasuu1.jpg";
import yaasuu2 from "../../../images/top/guest/yaasuu2.jpg";
import garyu from "../../../images/top/guest/garyu.jpg";
import keroponz from "../../../images/top/guest/keroponz.jpg";
import saori_dance from "../../../images/top/guest/saori_dance.jpg";
import hula from "../../../images/top/guest/hula.jpg";
import nkyan from "../../../images/top/guest/nkya-n.jpg";
import ballet from "../../../images/top/guest/ballet.jpg";

const GuestSection = () => {
  const guest_lists = [
    {
      name: "ニーリの旅",
      img: niri,
    },
    {
      name: "YAASUU",
      img: yaasuu1,
    },
    {
      name: "ケロポンズ",
      img: keroponz,
    },
  ];

  const guest_lists_small = [
    {
      name: "我琉",
      img: garyu,
    },
    {
      name: "宮古島創作芸能団 んきゃーんじゅく",
      img: nkyan,
    },
    {
      name: "saori dance school",
      img: saori_dance,
    },
    {
      name: "Pua'ena Miyako",
      img: hula,
    },
    {
      name: "飯島バレエスクール",
      img: ballet,
    },
  ];

  return (
    <>
      {" "}
      <section id="guest" className="sec_padding_base">
        <div className="inner">
          <div
            className="title_box m-auto"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="600"
            data-sal-easing="ease"
          >
            <img src={TitleGuest} alt="" loading="lazy" />
          </div>
          {/* <img src={GuestImage} alt="" className="guest_image m-auto" /> */}
          <ul className="guset_box">
            {guest_lists.map((ary) => (
              <li className="guest_item">
                <div className="guest_image">
                  <img src={ary.img} alt={ary.name} />
                </div>
                <p className="guest_name">{ary.name}</p>
              </li>
            ))}
          </ul>
          <ul className="guset_box guest_box_small">
            {guest_lists_small.map((ary) => (
              <li className="guest_item">
                <div className="guest_image">
                  <img src={ary.img} alt={ary.name} loading="lazy" />
                </div>
                <p className="guest_name">{ary.name}</p>
              </li>
            ))}
          </ul>

          <p className="guest_text">
            and more...
            <br />
            決定しだい順次発表！
          </p>
        </div>
      </section>
    </>
  );
};

export default GuestSection;
