import { graphql, Link } from "gatsby";
import React from "react";
import TitleBase from "../../atom/TitleBase";
import TitleBlog from "../../../images/top/blog_title.png";
import DummyImage from "../../../images/top/dammy_pic.png";
import Moment from "react-moment";

const BlogSection = ({ data }) => {
  const categoryColor = (categoryId: string) => {
    if (categoryId === "jf4yte65dvuv") {
      return "category_sky";
    } else if (categoryId === "fnrur0j1jc") {
      return "category_yellow";
    } else if (categoryId === "mjwt34q0k1t") {
      return "category_pink";
    }
  };

  return (
    <>
      {/* <div id="blog_sec">
        <div className="inner">
          <ul>
            {data.allMicrocmsBlogs.edges.map(({ node }) => (
              <li key={node.blogsId}>
                <Link to={`/blog/${node.blogsId}`}>{node.title}</Link>
                <Moment format="YYYY.MM.DD">{node.updatedAt}</Moment>
              </li>
            ))}
          </ul>
        </div>
      </div> */}

      <section id="blog_sec" className="sec_padding_base">
        <div className="inner">
          <div className="title_box m-auto">
            <img src={TitleBlog} alt="" />
          </div>
          <ul className="blog_list_box">
            {data.allMicrocmsBlogs.edges.slice(0, 3).map(({ node }) => (
              <li key={node.blogsId}>
                <Link to={`/blog/${node.blogsId}`} className="item">
                  <div className="image_area">
                    {node.eyecatch == null ? (
                      <img src={DummyImage} alt="" />
                    ) : (
                      <img src={node.eyecatch.url} alt="" loading="lazy" />
                    )}
                  </div>
                  <div className="text_area">
                    <p className="title">{node.title}</p>
                    <Moment format="YYYY.MM.DD" className="date">
                      {node.updatedAt}
                    </Moment>
                    <p
                      className={`category ${categoryColor(node.category.id)}`}
                    >
                      {node.category.name}
                    </p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <div className="base_button blog_button">
            <Link to={`/blog-index`}>すべての記事を見る</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSection;
