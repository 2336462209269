import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import Modal from "../../parts/Modal";
import Modal2 from "../../parts/Moda2";

const PastEventsSection = () => {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [num, setNum] = useState(1);

  const modalHandle1 = () => {
    setModal1(!open);
  };

  const modalHandle2 = () => {
    setModal2(!open);
  };

  return (
    <>
      <section id="past-event_sec" className="sec_padding_base">
        <div className="inner">
          <div className="title_box m-auto">
            <StaticImage
              src="../../../images/top/title_past.png"
              alt=""
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="600"
              data-sal-easing="ease"
              loading="lazy"
            />
          </div>
          <ul className="pastevent_list_box">
            <li className="item">
              <StaticImage
                src="../../../images/top/past_1.png"
                alt=""
                className="item_image m-auto"
              />
              <p className="item_title">
                第1回 宮古島冬まつり <br />
                #宮古島をアップデートせよ
              </p>
              <p className="item_text_area">
                開催日：2019年 2月 10日
                <br />
                場所： JTAドーム宮古島
                <br />
                参加人数： 2,300人
                <br />
                入場料：無料
              </p>
              <div className="base_button past_button">
                <button
                  onClick={() => {
                    setModal1(true);
                    setNum(1);
                  }}
                >
                  ご協賛企業・寄付者の皆様一覧
                </button>
              </div>
            </li>
            <li className="item">
              <StaticImage
                src="../../../images/top/past_2.png"
                alt=""
                className="item_image m-auto"
              />
              <p className="item_title">
                第2回 宮古島冬まつり
                <br />
                #宮古島愛をうたおう
              </p>
              <p className="item_text_area">
                開催日：2020年 2月 16日
                <br />
                場所： JTAドーム宮古島
                <br />
                参加人数： 3,000人
                <br />
                入場料：500円（高校生以下無料）
              </p>
              <div className="base_button past_button">
                <button
                  onClick={() => {
                    setModal2(true);
                    setNum(2);
                  }}
                >
                  ご協賛企業・寄付者の皆様一覧
                </button>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <Modal modal1={modal1} setModal1={setModal1} num={num} />
      <Modal2 modal2={modal2} setModal2={setModal2} num={num} />
    </>
  );
};

export default PastEventsSection;
