import React from "react";
import DummyLogo from "../../images/sponsor/sp-dummy.png";
import jta from "../../images/sponsor/logo_jta.png";
import locus from "../../images/sponsor/logo_locus.png";
import islandex from "../../images/sponsor/logo_islandex.png";
import rescape from "../../images/sponsor/logo_rescape.png";
import bethelight from "../../images/sponsor/logo_bethelight.png";
import yoshimoto from "../../images/sponsor/logo_yoshimoto.png";
import miyako from "../../images/sponsor/logo_miyako.png";
import izumi from "../../images/sponsor/logo_izumi.png";
import mediskin from "../../images/sponsor/logo_mediskin.png";
import sigma from "../../images/sponsor/logo_sigma.png";
import ripple from "../../images/sponsor/logo_ripple.png";
import oceans from "../../images/sponsor/logo_oceans.png";
import rugu from "../../images/sponsor/logo_rugu.png";
import serulean from "../../images/sponsor/logo_serulean.png";
import hamamoto from "../../images/sponsor/logo_hamamoto.png";
import aozora from "../../images/sponsor/logo_aozora.png";
import tomori from "../../images/sponsor/logo_tomori.png";
import unimat from "../../images/sponsor/logo_unimat.png";
import takken from "../../images/sponsor/logo_takken.png";
import usagiya from "../../images/sponsor/logo_usagiya.png";
import nikken from "../../images/sponsor/logo_nikken.png";
import islandworks from "../../images/sponsor/logo_islandworks.png";
import nk from "../../images/sponsor/logo_nk.png";
import tomishima from "../../images/sponsor/logo_tomishima.png";
import daishin from "../../images/sponsor/logo_daishin.png";
import ewave from "../../images/sponsor/logo_ewave.png";
import animal from "../../images/sponsor/logo_animal.png";
import myahk from "../../images/sponsor/logo_myahk.png";
import nics from "../../images/sponsor/logo_necs.png";
import polipoli from "../../images/sponsor/logo_polipoli.png";
import nkyan from "../../images/sponsor/logo_nkyan.png";
import r from "../../images/sponsor/logo_r.png";
import zeroko from "../../images/sponsor/logo_zeroko.png";
import root from "../../images/sponsor/logo_root.png";

const Sponsor2 = () => {
  return (
    <div>
      <section id="modal_sec">
        <div className="title_box m-auto">
          <span>第2回 宮古島冬まつり</span>
          <br />
          ご協賛企業様
        </div>
        <div className="logo_part">
          <ul className="list_box full_size">
            <p className="subtitle">特別協賛</p>
            <li className="item">
              <img src={jta} alt="" />
              <p>日本トランスオーシャン航空株式会社</p>
            </li>
          </ul>
          <p className="subtitle">協賛企業</p>
          <ul className="list_box full_size middle">
            <li className="item">
              <img src={locus} alt="" />
              <p>HOTEL LOCUS（ホテル ローカス）</p>
            </li>
            <li className="item">
              <img src={islandex} alt="" />
              <p>離島引越し便（アイランデクス株式会社）</p>
            </li>
            <li className="item">
              <img src={rescape} alt="" />
              <p>ザ・リスケープ（the rescape）</p>
            </li>
            <li className="item">
              <img src={bethelight} alt="" />
              <p>株式会社ビザライ</p>
            </li>
            <li className="item">
              <img src={yoshimoto} alt="" />
              <p>吉本興業ホールディングス株式会社</p>
            </li>
          </ul>
          <ul className="list_box half_size">
            <li className="item">
              <img src={izumi} alt="" />
              <p>株式会社いずみ</p>
            </li>
            <li className="item">
              <img src={mediskin} alt="" />
              <p>株式会社メディスキン</p>
            </li>
            <li className="item">
              <img src={sigma} alt="" />
              <p>株式会社シグマ</p>
            </li>
            <li className="item">
              <img src={ripple} alt="" />
              <p>株式会社りっぷる</p>
            </li>
            <li className="item">
              <img src={oceans} alt="" />
              <p>オーシャンズリゾート宮古島</p>
            </li>
            <li className="item">
              <img src={rugu} alt="" />
              <p>RuGu Glamping Resort</p>
            </li>
            <li className="item">
              <img src={serulean} alt="" />
              <p>有限会社セルリアンネット</p>
            </li>
            <li className="item">
              <img src={hamamoto} alt="" />
              <p>株式会社はまもと</p>
            </li>
            <li className="item">
              <img src={aozora} alt="" />
              <p>株式会社 青空</p>
            </li>
            <li className="item">
              <img src={tomori} alt="" />
              <p>友利産婦人科</p>
            </li>
            <li className="item">
              <img src={unimat} alt="" />
              <p>株式会社南西楽園リゾート</p>
            </li>
            <li className="item">
              <img src={takken} alt="" />
              <p>株式会社たっけんカンパニー</p>
            </li>
            <li className="item">
              <img src={usagiya} alt="" />
              <p>一魚一会（うさぎや）</p>
            </li>
            <li className="item">
              <img src={nikken} alt="" />
              <p>日建株式会社</p>
            </li>
            <li className="item">
              <img src={islandworks} alt="" />
              <p>有限会社アイランドワークス</p>
            </li>
            <li className="item">
              <img src={nk} alt="" />
              <p>合同会社NK自動車</p>
            </li>
            <li className="item">
              <img src={tomishima} alt="" />
              <p>富島創業株式会社</p>
            </li>
            <li className="item">
              <img src={daishin} alt="" />
              <p>株式会社大進興業</p>
            </li>
          </ul>
          <p className="subtitle">協力企業・団体</p>
          <ul className="list_box half_size">
            <li className="item">
              <img src={ewave} alt="" />
              <p>E-WAVE（イーウェイブ）</p>
            </li>
            <li className="item">
              <img src={animal} alt="" />
              <p>宮古島SAVE THE ANIMALS</p>
            </li>
            <li className="item">
              <img src={myahk} alt="" />
              <p>ミャークヌーマ宮古馬の会</p>
            </li>
            <li className="item">
              <img src={nics} alt="" />
              <p>エヌアイシー・ソフト株式会社</p>
            </li>
            <li className="item">
              <img src={polipoli} alt="" />
              <p>株式会社PoliPoli</p>
            </li>
            <li className="item">
              <img src={nkyan} alt="" />
              <p>んきゃーん塾</p>
            </li>
            <li className="item">
              <img src={r} alt="" />
              <p>R-Independent Education（琉塾）</p>
            </li>
            <li className="item">
              <img src={zeroko} alt="" />
              <p>ゼロ高等学院</p>
            </li>
            <li className="item">
              <img src={root} alt="" />
              <p>個別進学塾Root</p>
            </li>
          </ul>
          <ul className="list_box full_size">
            <p className="subtitle">後援</p>
            <li className="item">
              <img src={miyako} alt="" />
              <p>宮古島市</p>
            </li>
          </ul>
          <p className="subtitle">全協賛企業様、協賛者様一覧（順不同）</p>
          <p className="all_sponser">
            日本トランスオーシャン航空株式会社、離島引越し便（アイランデクス株式会社）、ホテルローカス、ザ・リスケープ、吉本興業ホールディングス株式会社、株式会社ビザライ、株式会社シグマ、株式会社いずみ、株式会社メディスキン、オーシャンズリゾート宮古島、有限会社セルリアンネット、株式会社りっぷる、
            RuGu Glamping
            Resort、日建株式会社、株式会社たっけんカンパニー、一魚一会・うさぎや、有限会社アイランドワークス、株式会社
            青空、株式会社南西楽園リゾート、株式会社大進興業、友利産婦人科、株式会社はまもと、富島総業株式会社、合同会社NK自動車、ゼロ高等学院、エヌアイシー・ソフト株式会社、R-Independent
            Education（琉塾）、個別進学塾Root（ルート）、株式会社まるちくタクシー、株式会社ちゃのまフーズ、株式会社どんぐりシステム、リヴプラス株式会社、ビースリー工業、株式会社ペイストリーク、有限会社吉田産業、宮古島東急ホテル&リゾーツ、有限会社富士製菓製パン、焼肉なかお、有限会社ニューサウスウインド、有限会社マルサンテック、宮古新報社、株式会社リゾートライフ、ちぎり木工所、合同会社ライム・エステイト、都一級建築設計事務所
            、株式会社ナカソネアルミ、三和建設、三和自動車学校、佐平建設、Riccoジェラート、TEPPAN
            Minowa、有限会社宮通企画、有限会社千代田開発、inBLU
            ～インブルー、楽島、株式会社オキジム、株式会社
            丸秀、フクギ保険事務所、ぐしけん印刷、マルキヨ味噌、住宅情報センター株式会社、先嶋産業株式会、PAPAYA、サチノミセ、さしば〜の羽、肴菜処　しょか、ファッションハウススパイラルⅡ、ACT-1、有限会社勝工業、有限会社
            三樹開発、LUNA building maintenance
            Co.,、とんかつ一番、株式会社八千代バス・タクシー、くろべぇ、Hair
            Care ＆Design
            GROWN、ボックリーのチョッキ、SUBAKO、アトリエkoa、佐和田はるか、砂川恵貞、泰川恵吾（Dr.
            ゴン）、北田匠、須田智恵子、GEO
            仲村、美容室root、山口薫、おおたゆうすけ、佐久田政幸E-WAVE
            <br />
            【後援】宮古島市、宮古島市教育委員会、宮古島商工会議所、宮古島観光協会、宮古島青年会議所
          </p>
        </div>
      </section>
    </div>
  );
};

export default Sponsor2;
