import React from "react";
import SponsorBook from "../../../images/top/sponser_book.png";

const SponsorSection = () => {
  return (
    <>
      <section id="sponser_sec">
        <div className="title_box m-auto">
          <span>第3回 宮古島冬まつり</span> <br />
          ご協賛企業様募集！
        </div>
        <div className="text_area">
          <p>
            「宮古島冬まつり」は、過去２度の開催で
            <br className="sp_only" />
            100を超える企業・個人の皆様のご支援により成功を納めることができました。
          </p>
          <p>
            第3回の開催となる今回も、企画の趣旨に賛同し、一緒にイベントを作り上げてくださる協賛企業様を募集しています。
          </p>
        </div>
        <img src={SponsorBook} alt="" className="sponser_book m-auto" />
        <div className="base_button sponser_button">
          {/* <a href="" onClick={() => alert("現在準備中です。")}>
            協賛資料ダウンロード
          </a> */}
        </div>
        <div className="base_button sponser_button">
          <a href="#contact_sec">お申込み・ご相談はこちら</a>
        </div>
      </section>
    </>
  );
};

export default SponsorSection;
