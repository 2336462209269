import React from "react";
import { graphql, Link } from "gatsby";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import Moment from "react-moment";

const BlogPage = ({ data }) => {
  const categoryColor = (categoryId: string) => {
    if (categoryId === "jf4yte65dvuv") {
      return "category_sky";
    } else if (categoryId === "fnrur0j1jc") {
      return "category_yellow";
    } else if (categoryId === "mjwt34q0k1t") {
      return "category_pink";
    }
  };
  const ogpBlogImage =
    data.microcmsBlogs.eyecatch && data.microcmsBlogs.eyecatch.url;

  const ogpBlogTitle = data.microcmsBlogs.title;
  const ogpBlogDesc = "";

  const writerImg = data.microcmsBlogs.writer.icon.url;

  const backgroundStyle = {
    backgroundImage: `url(${writerImg})`,
  };

  return (
    <>
      <Header
        ogpBlogImage={ogpBlogImage}
        ogpBlogTitle={ogpBlogTitle}
        ogpBlogDesc={ogpBlogDesc}
      />
      <div id="blog_detail">
        <div className="inner">
          <div className="meta_part">
            <Moment format="YYYY.MM.DD" className="date">
              {data.microcmsBlogs.updatedAt}
            </Moment>
            <span
              className={`category ${categoryColor(
                data.microcmsBlogs.category.id
              )}`}
            >
              {data.microcmsBlogs.category.name}
            </span>
          </div>
          <h1 className="blog_title">{data.microcmsBlogs.title}</h1>
          {/* <div className="writer_top">
            <div className="icon_area">
              {data.microcmsBlogs.writer.icon && (
                <img src={data.microcmsBlogs.writer.icon.url} alt="" />
              )}
            </div>
            <span className="writer_name">{data.microcmsBlogs.writer.name}</span>
          </div> */}
          {data.microcmsBlogs.eyecatch && (
            <img
              src={data.microcmsBlogs.eyecatch.url}
              alt=""
              className="eyecatch"
            />
          )}

          <div
            dangerouslySetInnerHTML={{
              __html: `${data.microcmsBlogs.content}`,
            }}
            className="blog_content"
          />
          <div className="writer_part">
            <p className="writer_title">記事を書いた人</p>
            <div className="writer_meta_part">
              <div className="icon_area" style={backgroundStyle}>
                {/* <img src={data.microcmsBlogs.writer.icon.url} alt="" /> */}
              </div>
              <div className="text_area">
                <p className="writer_name">{data.microcmsBlogs.writer.name}</p>
                <p className="writer_detail">
                  {data.microcmsBlogs.writer.profile}
                </p>
              </div>
            </div>
          </div>
          <div className="button_part">
            <Link to={`/blog-index`}>
              <div className="base_button blog_detail_button">
                <a href="">記事一覧に戻る</a>
              </div>
            </Link>
            <Link to={`/`}>
              <div className="base_button blog_detail_button">
                <a href="">トップに戻る</a>
              </div>
            </Link>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default BlogPage;

export const query = graphql`
  query ($blogsId: String) {
    microcmsBlogs(blogsId: { eq: $blogsId }) {
      content
      blogsId
      title
      category {
        name
        id
      }
      eyecatch {
        height
        url
        width
      }
      writer {
        name
        profile
        icon {
          height
          url
          width
        }
      }
    }
  }
`;
