import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import HeroSp from "../../../images/top/hero_sp.png";
import HeroBack from "../../../images/top/hero_back.png";
import HeroFrameTop from "../../../images/top/hero_frame_top.png";
import HeroFramePc from "../../../images/top/hero_frame_pc.png";

const HeroSection = () => {
  return (
    <>
      <div id="hero_sec">
        <div className="hero_top">
          <img
            src={HeroSp}
            alt="宮古島冬まつり"

            // data-sal="zoom-in"
            // data-sal-delay="100"
            // data-sal-duration="500"
            // data-sal-easing="ease"
          />
        </div>

        <div className="loop_wrap loop_wrap_top sp_only">
          <img src={HeroFrameTop} alt="" />
          <img src={HeroFrameTop} alt="" />
        </div>
        <div className="loop_wrap loop_wrap_top pc_only ">
          <img src={HeroFramePc} alt="" />
          <img src={HeroFramePc} alt="" />
        </div>
        <div className="loop_wrap loop_wrap_right sp_only">
          <img src={HeroFrameTop} alt="" />
          <img src={HeroFrameTop} alt="" />
        </div>
        <div className="loop_wrap loop_wrap_right pc_only">
          <img src={HeroFramePc} alt="" />
          <img src={HeroFramePc} alt="" />
        </div>
        <div className="loop_wrap loop_wrap_bottom sp_only">
          <img src={HeroFrameTop} alt="" />
          <img src={HeroFrameTop} alt="" />
        </div>
        <div className="loop_wrap loop_wrap_bottom pc_only">
          <img src={HeroFramePc} alt="" />
          <img src={HeroFramePc} alt="" />
        </div>
        <div className="loop_wrap loop_wrap_left sp_only">
          <img src={HeroFrameTop} alt="" />
          <img src={HeroFrameTop} alt="" />
        </div>
        <div className="loop_wrap loop_wrap_left pc_only">
          <img src={HeroFramePc} alt="" />
          <img src={HeroFramePc} alt="" />
        </div>
      </div>
    </>
  );
};

// const backgroundStyle = {
//   backgroundImage: `url(${HeroBack})`,
// };

export default HeroSection;
