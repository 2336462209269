import React from "react";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

//   modal1, setModal1, profName, content

const NiriModal = ({ modal, setModal, person }) => {
  return (
    <div className={`modal_wrapper  ${modal === true ? `modal_open` : ``}`}>
      <div className="modal_box">
        <div
          className="close"
          onClick={() => {
            setModal(false);
          }}
        >
          ×
        </div>
        <section id="modal_sec" className="niri_modal">
          <div className="content_box">
            <div className="prof_name">
              {person.name}
              <span>{person.furigana}</span>
            </div>
            <div className="prof_content">{person.content}</div>
            <div>
              {person.content2 !== "" && (
                <p style={{ marginTop: "20px" }}>{person.content2}</p>
              )}
            </div>
            <div>
              {person.link !== "" && (
                <a href={person.link} target="blank" style={{ color: "blue" }}>
                  {person.link}
                </a>
              )}
            </div>
          </div>
        </section>
        <div className="base_button modal_close_button">
          <button
            onClick={() => {
              setModal(false);
            }}
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
};

export default NiriModal;
