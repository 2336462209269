import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Sponsor = () => {
  return (
    <section id="sponser_all">
      <div className="inner">
        {" "}
        <div className="title_box m-auto">
          <StaticImage
            src="../../../images/top/title_sponsor.png"
            alt=""
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="600"
            data-sal-easing="ease"
            loading="lazy"
          />
        </div>
        <div className="logo_part">
          <ul className="list_box full_size">
            <p className="subtitle">特別協賛</p>
            <li className="item">
              <a href="https://www.jal.co.jp/jp/ja/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/logo_jta.png"
                  alt="JTA"
                />
                <p>日本トランスオーシャン航空株式会社</p>
              </a>
            </li>
          </ul>
          <p className="subtitle">協賛企業</p>
          <ul className="list_box full_size middle">
            <li className="item">
              <a href="http://www.skynet-sn.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/skynet.png"
                  alt=""
                />
                <p>株式会社スカイネット</p>
              </a>
            </li>
            <li className="item">
              <a href="https://www.rugu.co.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/rugu.png"
                  alt=""
                />
                <p>RuGu Glamping Resort</p>
              </a>
            </li>
            <li className="item">
              <a href="https://okinawa-cellular.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/okinawa.png"
                  alt=""
                />
                <p>沖縄セルラー電話株式会社</p>
              </a>
            </li>
            <li className="item">
              <a href="http://www.nikken-inc.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/nikken_housing.png"
                  alt=""
                />
                <p>株式会社日建ハウジング</p>
              </a>
            </li>
            <li className="item">
              <a href="https://web.anydeli.co.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/anydeli.png"
                  alt=""
                />
                <p>anydeli株式会社</p>
              </a>
            </li>
          </ul>

          <ul className="list_box half_size">
            <li className="item">
              <a
                href="https://okinawa-uds.co.jp/hotels/hotellocus/"
                target="_blank"
              >
                <StaticImage
                  src="../../../images/sponsor_all/locus.png"
                  alt=""
                />
                <p>HOTEL LOCUS/the rescape</p>
              </a>
            </li>
            <li className="item">
              <a href="https://islandex.co.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/islandex.png"
                  alt=""
                />
                <p>アイランデクス株式会社</p>
              </a>
            </li>
            <li className="item">
              <a href="https://bethelight-miyako.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/bethelight.png"
                  alt=""
                />
                <p>株式会社ビザライ</p>
              </a>
            </li>
            <li className="item">
              <a href="https://uminooto.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/island_works.png"
                  alt=""
                />
                <p>有限会社アイランドワークス</p>
              </a>
            </li>
            <li className="item">
              <a
                href="https://itp.ne.jp/info/470800623121770780/"
                target="_blank"
              >
                <StaticImage
                  src="../../../images/sponsor_all/tomishima.png"
                  alt=""
                />
                <p>富島総業株式会社</p>
              </a>
            </li>
            <li className="item">
              <a href="https://takken-company.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/takken.png"
                  alt=""
                />
                <p>株式会社たっけんカンパニー</p>
              </a>
            </li>
          </ul>

          <ul className="list_box small_size">
            <li className="item">
              <a href="https://miyakojima-bb.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/ripple.png"
                  alt=""
                />
                <p>株式会社りっぷる</p>
              </a>
            </li>
            <li className="item">
              <a href="https://www.hiroshif.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/hiroshi.png"
                  alt=""
                />
                <p>ひろし不動産</p>
              </a>
            </li>
            <li className="item">
              <a href="https://rakuen-kajitsu.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/rakuen.png"
                  alt=""
                />
                <p>楽園の果実</p>
              </a>
            </li>
            <li className="item">
              <a href="https://shigira.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/nanseirakuen.png"
                  alt=""
                />
                <p>株式会社南西楽園リゾート</p>
              </a>
            </li>
            <li className="item">
              <a
                href="https://www.mapion.co.jp/phonebook/M02014/47214/24730050886/"
                target="_blank"
              >
                <StaticImage
                  src="../../../images/sponsor_all/hamamoto.png"
                  alt=""
                />
                <p>株式会社はまもと</p>
              </a>
            </li>
            <li className="item">
              <a href="https://www.nikken.info/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/nikken.png"
                  alt=""
                />
                <p>日建株式会社</p>
              </a>
            </li>
            <li className="item">
              <a
                href="https://artavenue-miyako.co.jp/publication/"
                target="_blank"
              >
                <StaticImage
                  src="../../../images/sponsor_all/miyako_story.png"
                  alt=""
                />
                <p>宮古ストーリー</p>
              </a>
            </li>
            <li className="item">
              <StaticImage src="../../../images/sponsor_all/memo.png" alt="" />
              <p>メモの魔力100万部達成委員会</p>
            </li>
            <li className="item">
              <a href="https://shimojishima.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/shimoji.png"
                  alt=""
                />
                <p>下地島エアポートマネジメント株式会社</p>
              </a>
            </li>
          </ul>

          <ul className="list_box small_size2">
            <li className="item">
              <a href="https://www.facebook.com/dongurisystem" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/donguri.png"
                  alt=""
                />
                <p>株式会社どんぐりシステム</p>
              </a>
            </li>
            <li className="item">
              <a
                href="https://www.tokyuhotels.co.jp/miyakojima-h/index.html"
                target="_blank"
              >
                <StaticImage
                  src="../../../images/sponsor_all/tokyu.png"
                  alt=""
                />
                <p>宮古島東急ホテル&リゾーツ</p>
              </a>
            </li>
            <li className="item">
              <a href="https://www.jahana.co.jp/akido" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/archido.png"
                  alt=""
                />
                <p>ARCHI・DO株式会社</p>
              </a>
            </li>
            <li className="item">
              <a href="https://www.jahana.co.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/jahanagumi.png"
                  alt=""
                />
                <p>株式会社謝花組</p>
              </a>
            </li>
            <li className="item">
              <a href="https://maruchiku.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/maruchiku.png"
                  alt=""
                />
                <p>まるちくタクシー</p>
              </a>
            </li>
            <li className="item">
              <a
                href="https://www.daidokasai.co.jp/support/agency/1591/"
                target="_blank"
              >
                <StaticImage
                  src="../../../images/sponsor_all/fukugi.png"
                  alt=""
                />
                <p>フクギ保険事務所</p>
              </a>
            </li>
            <li className="item">
              <a href="https://www.taishin-ko.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/taishin.png"
                  alt=""
                />
                <p>株式会社大進工業</p>
              </a>
            </li>
            <li className="item">
              <a href="https://ricco-gelato.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/ricco.png"
                  alt=""
                />
                <p>RICCO gelato</p>
              </a>
            </li>
          </ul>
          <p className="subtitle">協力</p>
          <ul className="list_box half_size">
            <li className="item">
              <a href="http://ewavem.web.fc2.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/e_wave.png"
                  alt=""
                />
                <p>E-WAVE</p>
              </a>
            </li>
            <li className="item">
              <a href="https://miyako-shimako.com/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/shimako.png"
                  alt=""
                />
                <p>島の高等学院</p>
              </a>
            </li>
          </ul>
          <ul className="list_box full_size">
            <p className="subtitle">後援</p>
            <li className="item">
              <a href="https://www.city.miyakojima.lg.jp/" target="_blank">
                <StaticImage
                  src="../../../images/sponsor_all/miyakojimashi.png"
                  alt=""
                />
              </a>
              <p>宮古島市</p>
              <p>宮古島市教育委員会</p>
              <p>
                <a href="https://miyako-island.net/" target="_blank">
                  一般社団法人宮古島観光協会
                </a>
              </p>
            </li>
          </ul>

          <p className="subtitle">全協賛企業様、協賛者様一覧（順不同）</p>
          <p className="all_sponser">
            日本トランスオーシャン航空株式会社、
            <br />
            RuGu Glamping Resort、株式会社スカイネット、
            <br />
            株式会社日建ハウジング、沖縄セルラー電話株式会社、
            <br />
            anydeli株式会社、HOTEL LOCUS / the rescape、
            <br />
            アイランデクス株式会社、株式会社ビザライ、
            <br />
            有限会社アイランドワークス、 株式会社りっぷる、
            <br />
            富島総業株式会社、株式会社たっけんカンパニー、
            <br />
            株式会社はまもと、株式会社ひろし不動産、
            <br />
            株式会社南西楽園リゾート、下地島エアポートマネジメント株式会社、
            <br />
            メモの魔力100万部達成委員会、日建株式会社、
            <br />
            楽園の果実、株式会社どんぐりシステム、
            <br />
            株式会社大進興業、 宮古島東急ホテル＆リゾーツ、
            <br />
            ARCHI・DO株式会社、 株式会社謝花組、
            <br />
            まるちくタクシー、フクギ保険事務所、
            <br />
            Riccoジェラート、宮古ガス株式会社、
            <br />
            優誠建設、SKATEBOARD SHOP K8Z（ケィズ）、
            <br />
            アパマンショップ、 有限会社宮通企画、
            <br />
            株式会社オキジム、 Hair Care ＆ Design GROWN、
            <br />
            あかと、千代田開発、株式会社アートアベニュー宮古島、
            <br />
            株式会社ライテック、BAR PARADISO、
            <br />
            ひらら進学セミナー尾沢塾、 大阪教育大学 ICT教育支援ルーム、
            <br />
            朝陽組、saori dance school、 <br />
            一般社団法人 松本青年会議所、 古賀大喜、山口薫、
            <br />
            太田陽介、三森力豊、岡本将人、辻文将、
            <br />
            NK自動車、E-WAVE、美広社
            <br />
            【後援】
            <br />
            宮古島市、宮古島市教育委員会、
            <br />
            一般社団法人宮古島観光協会
          </p>
        </div>
      </div>
    </section>
  );
};

export default Sponsor;
