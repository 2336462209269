import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import Header from "../components/layout/Header";
import DummyImage from "../../../images/top/dammy_pic.png";
import "../sass/index.scss";
import Pagination from "../components/parts/pagination";

import Footer from "../components/layout/Footer";
import Moment from "react-moment";

const BlogIndexPage = ({ data }) => {
  const categoryColor = (categoryId: string) => {
    if (categoryId === "jf4yte65dvuv") {
      return "category_sky";
    } else if (categoryId === "fnrur0j1jc") {
      return "category_yellow";
    } else if (categoryId === "mjwt34q0k1t") {
      return "category_pink";
    }
  };

  //全記事
  const allBlogData = data.allMicrocmsBlogs.edges;

  //カテゴリーリスト
  const allCategories = Array.from(
    new Set(allBlogData.map((post) => post.node.category.name))
  );

  //記事絞り込みstate
  const [showPost, setShowPosts] = React.useState(allBlogData);

  //カテゴリー選択状態切り替え用
  const [isActive, setIsActive] = React.useState("all");

  //カテゴリー切り替え
  const setCategory = (category) => {
    if (category === "all") {
      setShowPosts(allBlogData);
      return;
    }
    const selectedPosts = allBlogData.filter(
      (post) => post.node.category.name === category
    );
    setShowPosts(selectedPosts);
    setIsActive(category);
  };

  //カテゴリボタン色切り替え
  const handleButton = (button) => {
    setIsActive(button);
  };

  return (
    <>
      <div id="blog_index_page">
        <Header />
        <div className="inner">
          <div className="title_box m-auto">
            <p>記事一覧</p>
          </div>
          <div>
            <p className="subtitle">カテゴリー</p>
            <div className="categories_button">
              <button
                className={`category_button ${
                  isActive === "all" ? "button_on" : ""
                }`}
                onClick={() => {
                  setCategory("all");
                  handleButton("all");
                }}
              >
                すべて
              </button>
              {allCategories.map((category, index) => (
                <button
                  className={`category_button ${
                    isActive === category ? "button_on" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    setCategory(category);
                    handleButton(category);
                  }}
                >
                  {category}
                </button>
              ))}
            </div>
            <div id="blog_sec">
              <ul className="blog_list_box">
                {showPost.map((item, index) => (
                  <li key={item.blogsId}>
                    <Link
                      to={`/blog/${item.node.blogsId}`}
                      className="item"
                      key={index}
                    >
                      <div className="image_area">
                        {item.node.eyecatch == null ? (
                          // <img src={DummyImage} alt="" />
                          <div>aa</div>
                        ) : (
                          <img src={item.node.eyecatch.url} alt="" />
                        )}
                      </div>
                      <div className="text_area">
                        <p className="title">{item.node.title}</p>
                        <Moment format="YYYY.MM.DD" className="date">
                          {item.node.updatedAt}
                        </Moment>
                        <p
                          className={`category ${categoryColor(
                            item.node.category.id
                          )}`}
                        >
                          {item.node.category.name}
                        </p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* <Pagination totalCount={data.allMicrocmsBlogs.totalCount} /> */}
            <div className="base_button blog_totop_button">
              <Link to={`/`}>トップに戻る</Link>
            </div>
          </div>
          {/* フッター */}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogIndexPage;

export const blogQuery = graphql`
  query {
    allMicrocmsBlogs {
      totalCount
      edges {
        node {
          blogsId
          title
          content
          category {
            name
            id
          }
          updatedAt
          writer {
            name
            profile
          }
          eyecatch {
            height
            url
            width
          }
        }
      }
    }
  }
`;

export const Head: HeadFC = () => <title>ブログ一覧</title>;
