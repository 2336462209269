import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const ConseptSection = () => {
  return (
    <>
      {" "}
      <section id="concept_sec" className="sec_padding_base">
        <div className="inner">
          <div
            className="title_box m-auto"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="600"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../../../images/top/title_concept.png"
              alt=""
              loading="lazy"
            />
          </div>
          <div
            className="image_area"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="600"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../../../images/top/concept_image.png"
              alt=""
              loading="lazy"
            />
          </div>
          <div className="text_area">
            <p>
              冬の宮古島を盛り上げる！「みんなで作る」をスローガンに、子どもから大人まで、島内外のさまざまな人が力を合わせて企画するイベントです。
            </p>
            <p></p>
          </div>
          <div className="jta_part">
            <p className="subtitle">特別協賛</p>
            <a
              href="https://www.jal.co.jp/jp/ja/"
              target="_blank"
              className="jta"
            >
              日本トランスオーシャン航空株式会社(JTA)
            </a>
          </div>
          <div className="pic_part">
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img1.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img2.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img3.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img4.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img5.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img6.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img7.jpg"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="img_item">
              <StaticImage
                src="../../../images/top/con_img8.jpg"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConseptSection;
