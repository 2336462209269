import React, { useEffect, useState } from "react";
import Sponsor2 from "./Sponsor2";
import Sponsor1 from "./Sponsor1";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const Modal = ({ modal1, setModal1, num }) => {
  return (
    <div className={`modal_wrapper ${modal1 === true ? `modal_open` : ``}`}>
      <div className="modal_box">
        <div
          className="close"
          onClick={() => {
            setModal1(false);
          }}
        >
          ×
        </div>
        <Sponsor1 />
        <div className="base_button modal_close_button">
          <button
            onClick={() => {
              setModal1(false);
            }}
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
