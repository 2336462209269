import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import Header from "../components/layout/Header";
import "../sass/index.scss";
import { StaticImage } from "gatsby-plugin-image";
import BlogArchive from "../components/parts/BlogArchive";
import TitleTimetable from "../images/top/title_timetable.png";
import Moment from "react-moment";
import HeroSection from "../components/section/top/HeroSection";
import BlogSection from "../components/section/top/BlogSection";
import ConseptSection from "../components/section/top/ConseptSection";
import ThemeSection from "../components/section/top/ThemeSection";
import ProjectSection from "../components/section/top/ProjectSection";
import GuestSection from "../components/section/top/GuestSection";
import PastEventsSection from "../components/section/top/PastEventsSection";
import GallerySlideSection from "../components/section/top/GallerySlideSection";
import GallerySection from "../components/section/top/GallerySection";
import SponsorSection from "../components/section/top/SponsorSection";
import BannerSection from "../components/section/top/BannerSection";
import ContactSection from "../components/section/top/ContactSection";
import { SnsSection } from "../components/section/top/SnsSection";
import Footer from "../components/layout/Footer";
import Sponsor from "../components/section/top/Sponsor";

const IndexPage = ({ data }) => {
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);

  return (
    <>
      <div className="wrap">
        <Header />

        {/* ヒーローイメージ */}
        <HeroSection />

        {/* <Link
          to="https://line.me/R/ti/p/@244hform"
          className="student_banner"
          target="_blank"
        >
          <StaticImage
            src="../images/top/volunteer.png"
            alt="ボランティア募集"
          />
        </Link> */}
        <div className="final_message">
          <div className="inner">
            <p className="final_title">【イベントは終了しました】</p>
            <p>
              皆さまのおかげで大盛況の一日となりました！
              <br />
              当日は5206名の方にご来場いただき、ステージやアリーナブース、飲食エリアは一日中楽しそうな声で賑わっていました。スタッフ一同、嬉しい気持ちでいっぱいです。
              <br />
              <br />
              ご協賛企業様をはじめ、ご出演いただいたゲストの皆さま、ご出展いただいた皆さま、ご支援いただいた多くの関係者ボランティアの方々、そしてご来場の全ての皆様に改めて感謝を申し上げます。本当にありがとうございました！
              <br />
              <br />
              宮古島冬まつり実行委員会一同
            </p>
            <br />
            <br />
            <p>
              <div className="base_button blog_button">
                <a href="https://miyako-winterfes.com/blog/m2dyj9moky/">
                  イベントレポートはこちら
                </a>
              </div>
            </p>
          </div>
        </div>

        {/* ブログ */}
        <BlogSection data={data} />

        {/* スポンサー */}
        <Sponsor />

        <section id="timetable_sec" className="sec_padding_base">
          <div className="inner">
            <div className="title_box m-auto">
              <img src={TitleTimetable} alt="タイムテーブル" />
            </div>
            <StaticImage
              src="../images/top/Timetable.png"
              className="timetable_img"
              alt="タイムテーブル"
            />
          </div>
        </section>

        {/* <div className="poster">
          <div className="inner">
            {" "}
            <p className="poster_title">
              <span>ステージ企画</span>
              <br />
              「ビジネスプレゼン大会」
        
            </p>
            <StaticImage
              src="../images/top/business_pitch.png"
              alt="ビジネスプレゼン"
            />
            <p className="sub">
              「宮古島の課題解決」をテーマしたプレゼンに、第一線で活躍する起業家・事業家がフィードバック！【登壇者決定しました】お楽しみに✨
              <br />
    
            </p>
          </div>
        </div> */}

        {/* コンセプト */}
        <ConseptSection />

        {/* テーマ */}
        {/* <ThemeSection /> */}

        {/* プロジェクト */}
        <ProjectSection />

        {/* ゲスト */}
        <GuestSection />

        {/* 過去のイベント */}
        <PastEventsSection />

        {/* スライドショー */}
        <GallerySlideSection />

        {/* ギャラリー */}
        {/* <GallerySection /> */}

        <div style={{ marginTop: 60 }}></div>

        {/* スポンサー募集 */}
        <SponsorSection />

        {/* バナー */}
        {/* <BannerSection /> */}
        <div style={{ marginTop: 60 }}></div>

        {/* お問い合わせ */}
        <ContactSection
          privacyPolicy={privacyPolicy}
          setPrivacyPolicy={setPrivacyPolicy}
        />

        {/* SNS */}
        <SnsSection />

        {/* フッター */}
        <Footer
          privacyPolicy={privacyPolicy}
          setPrivacyPolicy={setPrivacyPolicy}
        />
      </div>
    </>
  );
};

export default IndexPage;

export const blogQuery = graphql`
  query {
    allMicrocmsBlogs {
      edges {
        node {
          blogsId
          title
          content
          category {
            name
            id
          }
          updatedAt
          writer {
            name
            profile
          }
          eyecatch {
            height
            url
            width
          }
        }
      }
    }
  }
`;

export const Head: HeadFC = () => <title>宮古島冬まつり</title>;
