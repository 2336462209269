import React from "react";
import Header from "../components/layout/Header";
import project_virtual_main from "../images/virturalevent/project_virtual_main.jpg";
import minecraft_main from "../images/virturalevent/minecraft_main.jpg";
import minecraft_movie from "../images/virturalevent/minecraft_movie1.mp4";
import ict from "../images/virturalevent/ict-center.jpg";
import workshop1 from "../images/virturalevent/workshop1.jpg";
import mine2 from "../images/virturalevent/mine2.jpg";
import { Link } from "gatsby";
import Footer from "../components/layout/Footer";

const virtualevent = () => {
  return (
    <div>
      <Header
        ogpBlogImage={project_virtual_main}
        ogpBlogTitle="バーチャル同時開催"
        ogpBlogDesc="マイクラの世界でバーチャルでも冬まつり体験！"
      />
      <div id="blog_detail">
        <div className="inner">
          <h1 className="blog_title">バーチャル同時開催</h1>
          <img src={project_virtual_main} alt="" className="eyecatch" />
          <div className="blog_content project_page">
            <h2 className="niri_title">
              人気ゲーム「マインクラフト」の世界で冬まつりを体験!?
            </h2>
            <h2>どんなことをやるのか</h2>
            <p>
              デジタルのブロックを使って自由に遊べるゲーム
              <strong>「マインクラフト（マイクラ）」</strong>
              でイベント会場を再現し、バーチャル世界でも冬まつりを体験しよう！という企画。
            </p>
            <img src={minecraft_main} alt="バーチャル開催" />
            <em>
              世界中で大人気の
              <a href="https://www.minecraft.net/ja-jp" target="_blank">
                マインクラフト
              </a>
            </em>

            <p>
              宮古島にある通信制サポート校「島の高等学院」の高校生が主体となり取り組んでいます。
            </p>
            <p>
              再現には、「エージェント」と呼ばれるマインクラフト内でのロボットプログラミングをを活用。屋内については構想段階ですが、マインクラフト内で最大３０人ほど参加できるミニゲームを導入する予定です。
            </p>

            <video playsInline controls style={{ width: "100%" }}>
              <source src={minecraft_movie} />
            </video>
            <em>プログラムを組むと"エージェント"が自動で動いてくれます</em>

            <h2>【大阪教育大学×島の高等学院の生徒による新たな試み】</h2>
            <p>
              プログラミングを組むための技術面で、高校生だけでは難しい部分がありました。
            </p>
            <p>
              そこで、過去に教育版マインクラフトでプログラミングを用いてキャンパスを再現した
              <strong>「大阪教育大学ICT教育支援ルーム」</strong>
              の皆さんとタッグを組み、教育版マインクラフトのノウハウを伝授してもらいながら一緒に再現を目指します。
            </p>
            <img src={ict} alt="バーチャル開催" />
            <em>大阪教育大学ICT教育支援ルームの皆さん</em>
            <h2></h2>
            <p>
              現在は、JTAドーム再現のためのワークショップを週１回行っており、高校生１人に対して大阪教育大学生１人がプログラミングの指導に付く体制で実施しています。
            </p>
            <img src={mine2} alt="バーチャル開催" />
            <img src={workshop1} alt="バーチャル開催" />
            <em>ワークショップの様子</em>

            <p>
              プロジェクトの進捗は{" "}
              <a href="https://twitter.com/oku_mee_project" target="_blank">
                宮古島冬まつりMinecraftプロジェクト supported by
                OKUICTRoom(Twitter)
              </a>
              でも発信していますので、ぜひご覧ください！
            </p>

            <hr />

            <p>【大阪教育大学ICT教育支援ルームの紹介】</p>
            <p>
              大阪教育大学ICT教育支援ルームは、大学のICT教育全般の技術的なサポートを目的として、支援を行っています。
              <br />
              そんなICT教育支援ルームの学生らが、昨年11月1日から３日の3日間
              <strong>「Minecraft：Eduction Edition」</strong>
              を使って柏原キャンパス共通講義棟（A棟）を再現する様子をYoutube
              Live配信するイベントを実施しました。
              <br />
              誰でも気軽にプログラミングを学ぶことができることを伝えることを目的に実施され、大学祭に合わせて行うことで、大学祭全体を盛り上げることにも貢献しました。
              <br />
              詳細は以下のリンクからご参照ください。
              <br />
              <p>
                <a
                  href="https://osaka-kyoiku.ac.jp/university/kouhou/topics/detail.html?itemid=7271&dispmid=5480"
                  target="_blank"
                  style={{ maxWidth: 100, width: "100% !important" }}
                >
                  学生らがMinecraftの世界でキャンパス内の建物を再現
                </a>
              </p>
            </p>
          </div>
        </div>
        <div className="prof_sec">
          <div className="inner">
            <h2>関連リンク</h2>
            <p className="niri_sns">
              <a
                href="https://www.osaka-kyoiku.ac.jp/~ipc/ictedu-support/"
                target="_blank"
              >
                大阪教育大学ICT教育支援ルーム 公式HP
              </a>
              <br />
              <a href="https://twitter.com/oku_ict" target="_blank">
                大阪教育大学ICT教育支援ルーム 公式Twitter
              </a>
              <br />
              <a
                href="https://www.instagram.com/miyako_winterfes/?r=nametag"
                target="_blank"
              >
                宮古島冬まつり Instagram
              </a>
              <br />
              <a href="https://www.facebook.com/myahklab/" target="_blank">
                宮古島冬まつり Facebook
              </a>
              <br />
              <a href="https://twitter.com/mya_hk_lab" target="_blank">
                宮古島冬まつり Twitter
              </a>
            </p>
          </div>
          <div className="button_part">
            <Link to={`/`}>
              <div className="base_button blog_detail_button">
                <a href="">トップに戻る</a>
              </div>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default virtualevent;
