import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slide1 from "../../../images/top/slides/slide_1.jpg";
import Slide2 from "../../../images/top/slides/slide_2.jpg";
import Slide3 from "../../../images/top/slides/slide_3.jpg";
import Slide4 from "../../../images/top/slides/slide_4.jpg";
import Slide5 from "../../../images/top/slides/slide_5.jpg";
import Slide6 from "../../../images/top/slides/slide_6.jpg";

const GallerySlideSection = () => {
  return (
    <>
      <div id="slide_sec">
        <div className="loop_wrap">
          <ul className="slide_box">
            <li>
              <img src={Slide1} alt="" />
            </li>
            <li>
              <img src={Slide2} alt="" />
            </li>
            <li>
              <img src={Slide3} alt="" />
            </li>
            <li>
              <img src={Slide4} alt="" />
            </li>
            <li>
              <img src={Slide5} alt="" />
            </li>
          </ul>
          <ul className="slide_box">
            <li>
              <img src={Slide1} alt="" />
            </li>
            <li>
              <img src={Slide2} alt="" />
            </li>
            <li>
              <img src={Slide3} alt="" />
            </li>
            <li>
              <img src={Slide4} alt="" />
            </li>
            <li>
              <img src={Slide5} alt="" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default GallerySlideSection;
