import { Link } from "gatsby";
import React, { useState } from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import niri_top from "../images/niri/niri-top.jpg";
import niri_top2 from "../images/niri/niri_image2.jpg";
import niri01 from "../images/niri/niri01.jpg";
import niri02 from "../images/niri/niri02.jpg";
import niri03 from "../images/niri/niri03.jpg";
import niri04 from "../images/niri/niri04.jpg";
import hiroko from "../images/niri/hiroko.jpg";
import mayumi from "../images/niri/mayumi.jpg";
import misaki from "../images/niri/misaki.jpg";
import akane from "../images/niri/akane.jpg";
import misa from "../images/niri/misa.jpg";
import kiyofumi from "../images/niri/kiyofumi.jpg";
import shiori from "../images/niri/shiori.jpg";
import ayaka from "../images/niri/ayaka.jpg";
import seiya from "../images/niri/seiya.jpg";
import ryuua from "../images/niri/ryuua.jpg";
import shone from "../images/niri/shone.jpg";
import arrie from "../images/niri/arrie.jpg";
import geragera from "../images/niri/geragera.jpg";
import satie from "../images/niri/satie_2.jpg";
import nkya_n from "../images/niri/nkya-n.jpg";
import timpab from "../images/niri/timpab.jpg";
import kuro from "../images/niri/kuro.jpg";
import sandaru1 from "../images/niri/sandaru1.jpg";
import sandaru2 from "../images/niri/sandaru2.jpg";
import sandaru3 from "../images/niri/sandaru3.jpg";
import sandaru4 from "../images/niri/sandaru4.jpg";
import NiriModal from "../components/parts/NiriModal";
import Saya from "../images/niri/saya.jpg";
import yukiko from "../images/niri/yukiko.jpg";
import saya_solo from "../images/niri/saya_solo.jpg";
import nkyan_small from "../images/niri/nkyan_small.jpg";

const niri = () => {
  const [modal, setModal] = useState(false);
  const [person, setPerson] = useState({});

  const niri_act_arry = [
    {
      name: "儀間寛子",
      furigana: "(ギマ ヒロコ)",
      role: "脚本演出作曲/ボーカル",
      img: hiroko,
      content:
        "富山県出身、18歳で上京し役者・ミュージカル女優活動を経てシンガーソングライターに転身。２年間海外でのアーティスト活動をする中で、アメリカのグラミー賞授賞式に２度参列し、イタリアでは文化勲章を叙勲。その後結婚を機に伊良部島に移住。宮古テレビでは歌のおねえさんとして番組出演、その他CM出演や楽曲提供も行う。地域に根ざした活動を広げる中で、宮古島の人々だけが放つ”輝き”に触れる。コロナ禍で失われかけている島の文化風習・伝統・団結力…芸術の力でそれらを分かりやすく人々に伝え、心に刻み、この先の未来を生きる子ども達に託していきたいと願い、2022年より「ニーリの旅」プロジェクトを企画。今回のミュージカルショーでは脚本・作曲・演出・出演を担当する。『表現することで繋がったその先の世界へ、共に旅をしましょう』",
    },
    {
      name: "島尻真由美",
      furigana: "(シマジリ マユミ)",
      role: "制作/ピアノ",
      img: mayumi,
      content:
        "宮古高校卒業。尚美ミュージックカレッジ専門学校・ピアノ学科を卒業。宮古島に戻り、現在は地元合唱団のピアノ伴奏、保育園での音楽講師、宮古島の様々なアーティストのピアノ伴奏をする。これまで、子ども達、ご年配の方々、ソプラノ歌手、フォークソング、ジャズシンガー、楽器の伴奏に携わる。",
    },
    {
      name: "下地美咲",
      furigana: "(シモジ ミサキ)",
      role: "三線・唄",
      img: misaki,
      content:
        "沖縄県宮古島出身。幼い頃に琉球舞踊を習い始めたことをきっかけに、三線と出会い習い始める。島内で行われる各民謡大会で優勝をし、初代三冠王となる。三線歌い手、宮古民謡継承者一員としての目標を持ちながら、島内外で行われるイベント等で活動をしている。",
    },
    {
      name: "森光佐",
      furigana: "(モリミサ)",
      role: "俳優",
      img: misa,
      content:
        "福岡県北九州市出身。1997年より俳優活動開始。地元のアマチュア劇団に入団し、北九州芸術劇場のプロデュース作品にも数多く出演を重ね、福岡を拠点とし東京・大阪愛知などでも公演を行う。（主な出演作の演出家・宮城聡/ク・ナウカシアターカンパニー・内藤裕敬/南河内万歳一座・白井晃　etc）2013年宮古島移住。2015年11月より島唯一の子ども劇団『劇団かなやらび』演技指導講師に就任以後、脚本・演出・指導を手掛け、現在までに4作品を発表している。",
    },
    {
      name: "ARRie",
      furigana: "(アリー)",
      role: "手話パフォーマンス",
      img: arrie,
      content:
        "聞こえる世界と聞こえない世界の間で生きるパフォーマー。曲の世界観や歌詞の背景を手話・ジェスチャー・芝居・ダンスを用いて音を見えるように表現する。自身も手話という言語を学んでいくうちに手話に魅了される。手で音を奏で、全身で言葉を伝えることで、誰かの“想い“や”夢“へと繋がる可能性があると信じている。国籍・言語・性別・障害・年齢・関係なく「音楽を一緒に楽しめる空間づくり」を目標に様々な場所でパフォーマンスを広げるため、あらゆるステージに挑戦している。手話の魅力や表現する楽しさ、素晴らしさを伝えている。",
    },
    {
      name: "Satie Sagara (相良姉妹)",
      furigana: "",
      role: "Faitaile フェテイルの舞",
      img: satie,
      content:
        "”Faitaile フェテイルの舞”創始者。幼少期はお神楽の鬼、人魚姫などの架空の存在に陶酔。水泳でオリンピックを目指し、身体の作り方に興味を持つ。2004年より婦人科で管理薬剤師を勤める。女性のホルモンバランスを西洋薬で調整する現代医療に違和感を覚え本来の自然美感覚を取り戻す舞やワークを考案。同病院の建物内にカルチャースクールを共同オープンしワークを広める。2010年渡仏し世界展開。2011年イタリアで開催された『SilkroadProject』にて姉妹で2年連続最高賞を受賞。”芸術の新機軸”と称された”Faitaile フェテイルの舞”創始者となる。国内外で舞台演出、振付、講演、自然とつながるリトリート、ダンス未経験者を募集しパリ公演を行うなど『芸術は習うものではない。思い出すもの。自然や自分の内側とつながること。』を提唱している。",
    },
    {
      name: "平良茜",
      furigana: "たいらあかね",
      role: "俳優",
      img: akane,
      content: "",
    },
    {
      name: "ゲラゲラ星人",
      furigana: "",
      role: "宮古島住みます芸人",
      img: geragera,
      content:
        "お笑い芸人。吉本興業所属。NSC大阪32期卒。大阪・東京と舞台やテレビを中心に活動するが、2021年6月より「宮古島住みます芸人」として拠点を宮古島に移して活動中。現在は、YouTubeや宮古テレビ「週刊テレビあがんにゃ」MC、FMみやこ「宮古島ゲラゲラジオ」パーソナリティなど、宮古島の魅力を島内外に発信中。なんでもゲラゲラ笑ってしまう宇宙人。",
    },
    {
      name: "垣花清文",
      furigana: "(カキハナキヨフミ)",
      role: "口説・囃子",
      img: kiyofumi,
      content: "",
    },
    {
      name: "垣花汐璃",
      furigana: "(カキハナシオリ)",
      role: "ボーカル",
      img: shiori,
      content: "",
    },
    {
      name: "砂川彩夏",
      furigana: "(スナカワアヤカ)",
      role: "三線・唄・三板",
      img: ayaka,
      content: "",
    },
    {
      name: "儀間誠弥",
      furigana: "(ギマセイヤ)",
      role: "パーカッション",
      img: seiya,
      content: "",
    },
    {
      name: "長間琉愛",
      furigana: "(ナガマリュウア)",
      role: "獅子舞",
      img: ryuua,
      content: "",
    },
    {
      name: "湊ナラヤン渚音",
      furigana: "(ミナトナラヤンショーン)",
      role: "俳優",
      img: shone,
      content:
        "平良中学校1年生。日本人とフィジー人のハーフ。2歳までタイのサムイ島で育つ。2012年に家族で宮古島に移住。小学1年生で『琉球国祭り太鼓』に入団し、継続中。小学4年生から『レオクラブ』に入団し、道路清掃、ビーチクリーン等、社会奉仕活動に参加しながら『レオクラブバンド』のボーカルとしても活躍。2022年2月から『海洋少年団』の一員としてヨットを訓練中。同年4月、演劇に目覚め『劇団かなやらび』に入団、初舞台で『ニーリの花』に出演。また、ピアノを独学で挑戦中。",
    },
    {
      name: "大羽由希子",
      furigana: "(オオバユキコ)",
      role: "ヴァイオリン",
      img: yukiko,
      content:
        "桐朋学園芸術短期大学卒業。ジャンルを問わず幅広い演奏活動を行うとともに、後進の指導にも力を入れている。骨体操とヒモトレを取り入れた楽しいレッスンが好評。愛知県豊橋市にてYukikoヴァイオリン教室主宰。ヴァイオリン骨体操指導員。",
      link: "https://violin-toyohashi.com/",
    },
    {
      name: "宮古島創作芸能団 んきゃーんじゅく",
      furigana: "",
      role: "",
      img: nkyan_small,
      content:
        "男塾 武-Doo(おとこじゅく ぶどぅー)・美座(かぎざ)・Drum&Dance TEAM BP  の3 チームを統括する組織。んきゃーんじゅくとは、直訳すると「昔の教え」。 温故知新を念頭に置き、沖縄や宮古島の伝統芸能や先人からの教えを元に、新たなる芸能を 生み出して後世に繋げる事を目標に活動している。",
    },
  ];

  const nkyan_prof = {
    name: "宮古島創作芸能団 んきゃーんじゅく",
    furigana: "",
    role: "",
    img: geragera,
    content:
      "男塾 武-Doo(おとこじゅく ぶどぅー)・美座(かぎざ)・Drum&Dance TEAM BP  の3 チームを統括する組織。んきゃーんじゅくとは、直訳すると「昔の教え」。 温故知新を念頭に置き、沖縄や宮古島の伝統芸能や先人からの教えを元に、新たなる芸能を 生み出して後世に繋げる事を目標に活動している。",
  };
  const kaori_prof = {
    name: "染織工房timpab/石嶺香織",
    furigana: "(イシミネ カオリ)",
    role: "",
    img: geragera,
    content:
      "2008年、宮古上布を学ぶために宮古島に移住。2011年より染織工房timpubを主宰。「宮古島のいのちを身に纏う」をコンセプトに、苧麻や綿や紙糸などの自然素材から糸を紡ぎ、島の草木で染め、織る。また宮古織や宮古麻織、アンティークの宮古上布を使った織衣や小物、紅型や刺繍とのコラボ作品も制作している。",
  };
  const kuro_prof = {
    name: "紅型工房くろ/黒川俊枝",
    furigana: "(クロカワ タカエ)",
    role: "",
    img: geragera,
    content:
      "1999年知花千賀子先生の紅型教室を見学したのをきっかけに紅型を始める。2002年、沖縄県工芸指導所（現産業支援センター）で１年間の紅型研修を修了。2010年より福岡に戻り、仕事の傍ら作品作りを続けて今に至る",
  };

  const guest_act_arry = [
    {
      name: "Saya",
      furigana: "",
      role: "",
      img: saya_solo,
      content:
        "大阪府豊中市出身。フラダンサー・フラインストラクター。東京サンディーズフラスタジオ所属。2001年宮古島移住。HUNA（精神性）を継ぐ系譜の正統的フラ（踊り）、オリ（詠唱）、メレ（歌）、ハワイ文化や哲学を学び続けながら、さまざまなイベントに出演。2018年～宮古島フラサークルSaya's hula ohana のインストラクターを務める。フラを学ぶことや音楽を通して表現することが、世界や一人一人の平和と繋がることを体感しながら、ダンサーとしてライブ活動、インストラクターとして指導やサークルのステージ活動、地域活動などでフラの楽しさと魅力を伝えている。",
    },
    {
      name: "Saya's HULA ohana Miyakojima",
      furigana: "",
      role: "",
      img: Saya,
      content:
        "Saya's HULA ohana Miyakojima（宮古島フラサークル） ALOHA＋PEACE=HULA をテーマに、心と身体を調律し、美しいフラを踊れるようレッスンしています。さまざまな宮古島のイベントに出演。こどもから大人、初心者から経験者まで、仲良く楽しくフラの活動中。メンバー随時募集中 Instagram @saya.hula.ohana.miyakojima / Facebook @ALOHA.MIYAKO各クラス月3回 @平良(土曜こどもクラス 午後13時～、土曜大人クラス 午後13時半～15時、木曜大人クラス 夜19時～20時半)",
    },
  ];

  const workshop_lists = [
    {
      name: "松下智美",
      furigana: "まつしたともみ",
    },
    {
      name: "小西桃花",
      furigana: "こにしももか",
    },
    {
      name: "前田琥珀",
      furigana: "まえだこはく",
    },
    {
      name: "前田真由美",
      furigana: "まえだまゆみ",
    },

    {
      name: "酒井丈",
      furigana: "さかいじょう",
    },
    {
      name: "砂川昌太郎",
      furigana: "すなかわしょうたろう",
    },
    {
      name: "友利奏咲",
      furigana: "ともりかなさ",
    },
    {
      name: "下里早也香",
      furigana: "しもさとさやか",
    },
    {
      name: "下里朔",
      furigana: "しもさとさく",
    },
    {
      name: "Zen",
      furigana: "ゼン",
    },
    {
      name: "矢野雅人",
      furigana: "やのまさと",
    },
    {
      name: "岡本美紀",
      furigana: "おかもとみき",
    },
    {
      name: "岡本真一",
      furigana: "おかもとしんいち",
    },
    {
      name: "岡本一美",
      furigana: "おかもとかずみ",
    },
    {
      name: "下地健吾",
      furigana: "しもじけんご",
    },

    {
      name: "酒井愛",
      furigana: "さかいあい",
    },
    {
      name: "酒井咲茉",
      furigana: "さかいえま",
    },
    {
      name: "下里孝子",
      furigana: "しもさとたかこ",
    },
    {
      name: "下里博章",
      furigana: "しもさとひろあき",
    },
    {
      name: "長崎久子",
      furigana: "ながさきひさこ",
    },
    {
      name: "平良夏々美",
      furigana: "たいらななみ",
    },
    {
      name: "池間正",
      furigana: "いけまただし",
    },
    {
      name: "池間加代子",
      furigana: "いけまきょこ",
    },
    {
      name: "池間みか",
      furigana: "いけまみか",
    },
    {
      name: "福原あゆみ",
      furigana: "ふくはらあゆみ",
    },
    {
      name: "砂川葉子",
      furigana: "すなかわようこ",
    },
    {
      name: "名渡山桂",
      furigana: "などやまけい",
    },
    {
      name: "新城直子",
      furigana: "しんじょうなおこ",
    },
    {
      name: "柴田幸代",
      furigana: "しばたさちよ",
    },
    {
      name: "大鹿貴子",
      furigana: "おおしかたかこ",
    },
    {
      name: "新城直子",
      furigana: "しんじょうなおこ",
    },
  ];

  const handleModal = (person) => {
    setPerson(person);
    setModal(true);
  };

  return (
    <div>
      <Header
        ogpBlogImage={niri_top2}
        ogpBlogTitle="【ステージ】ニーリの旅"
        ogpBlogDesc="島のアーティストと子供たちによるエンタメ創出プロジェクト"
      />
      <div id="blog_detail">
        <div className="inner">
          <h1 className="blog_title">【メインステージ】ニーリの旅</h1>
          <img src={niri_top2} alt="" className="eyecatch" />
          <div className="blog_content">
            <h2 className="niri_title">
              宮古島から生まれる、歌ありダンスあり・笑いありの新感覚ミュージカルショー！
            </h2>
            <h2>"ニーリの旅"とは？</h2>
            <p>
              <strong>宮古島のアーティスト</strong>と
              <strong>島の子どもたち</strong>
              によるエンタメプロジェクト。音楽や演劇を通じて、まだ知られていない宮古島の伝統芸能やものづくり文化の価値を残し、広める活動をしています。
            </p>
            <p>
              今年4月には、沖縄生まれの名曲「ダイナミック琉球」を宮古島流にアレンジしたミュージックビデオを制作。
            </p>
            <div className="video">
              <iframe
                src="https://www.youtube.com/embed/r9788ZXypro"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <p>その他、宮古島の数々のフェスやイベントに出演しています。</p>
            <div className="img_box">
              <img src={niri01} alt="ニーリの旅" />
              <img src={niri02} alt="ニーリの旅" />
              <img src={niri03} alt="ニーリの旅" />
              <img src={niri04} alt="ニーリの旅" />
            </div>
            <div className="video video_2">
              {/* <iframe
                src="https://www.youtube.com/embed/_nKHIL1cvjA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe> */}

              {/* <div className="img_box"> */}
              <img src={sandaru4} alt="ニーリの旅" />
              {/* <img src={sandaru3} alt="ニーリの旅" />
                <img src={sandaru2} alt="ニーリの旅" />
                <img src={sandaru1} alt="ニーリの旅" /> */}
              {/* </div> */}
            </div>
            <em>宮古TV「サンだる」でも放送されました</em>
            <h2>冬まつりで「宮古島ならでは」の新しいエンタメステージを！</h2>
            <p>
              ニーリの旅のショーも<strong>"みんなでつくる"</strong>
              <br />
              ショー。プロからアマチュアまで、大人も子どもも一緒になって、宮古島でしかできない最高のエンターテイメントを作ります！
            </p>
          </div>
        </div>
        <div className="prof_sec">
          <div className="inner">
            <h2>出演者</h2>
            <ul className="niri_act_box">
              {niri_act_arry.map((ary) => (
                <li>
                  <img src={ary.img} alt={ary.name} />
                  <p className="name">
                    {ary.name}
                    <span>{ary.furigana}</span>
                  </p>
                  <p className="role">{ary.role}</p>
                  {ary.content !== "" && (
                    <a
                      onClick={() => {
                        handleModal(ary);
                      }}
                      className="prof_btn"
                    >
                      プロフィール
                    </a>
                  )}
                </li>
              ))}
            </ul>
            {/* <div className="nkya_n">
              <img src={nkya_n} alt="んきゃーんじゅく" />
              <p className="name">宮古島創作芸能団 んきゃーんじゅく</p>
              <a
                onClick={() => {
                  handleModal(nkyan_prof);
                }}
                className="prof_btn"
              >
                プロフィール
              </a>
            </div> */}
            <h2>ゲスト出演</h2>
            <ul className="niri_act_box">
              {guest_act_arry.map((ary) => (
                <li>
                  <img src={ary.img} alt={ary.name} />
                  <p className="name">
                    {ary.name}
                    <span>{ary.furigana}</span>
                  </p>
                  <p className="role">{ary.role}</p>
                  {ary.content !== "" && (
                    <a
                      onClick={() => {
                        handleModal(ary);
                      }}
                      className="prof_btn"
                    >
                      プロフィール
                    </a>
                  )}
                </li>
              ))}
            </ul>
            <h2>出演者</h2>
            <ul className="">
              {workshop_lists.map((ary) => (
                <p>
                  ・{ary.name}
                  <span style={{ fontSize: "13px" }}>({ary.furigana})</span>
                </p>
              ))}
            </ul>
            <h2>衣装提供・デザインスタッフ</h2>
            <ul className="niri_act_box niri_store">
              <li>
                <img src={timpab} alt="timpab" />
                <p className="store">染織工房timpab</p>
                <p className="name">
                  石嶺香織
                  <span>(イシミネ カオリ)</span>
                </p>
                <a
                  onClick={() => {
                    handleModal(kaori_prof);
                  }}
                  className="prof_btn"
                >
                  プロフィール
                </a>
              </li>
              <li>
                <img src={kuro} alt="timpab" />
                <p className="store">紅型工房くろ</p>
                <p className="name">
                  黒川俊枝
                  <span>(クロカワ タカエ)</span>
                </p>
                <a
                  onClick={() => {
                    handleModal(kuro_prof);
                  }}
                  className="prof_btn"
                >
                  プロフィール
                </a>
              </li>
            </ul>
            <h2>ニーリの旅 SNS</h2>
            <p className="niri_sns">
              <a
                href="https://www.instagram.com/niirinotabi/?hl=ja"
                target="_blank"
              >
                Instagram
              </a>
              <br />
              <a
                href="https://www.youtube.com/channel/UCFL1ZzNfZgRFFnVHJJkLcZQ"
                target="_blank"
              >
                Youtube
              </a>
              <br />
              <a
                href="https://www.facebook.com/profile.php?id=100077197165646"
                target="_blank"
              >
                Facebook
              </a>
            </p>
          </div>
          <div className="button_part">
            <Link to={`/`}>
              <div className="base_button blog_detail_button">
                <a href="">トップに戻る</a>
              </div>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
      <NiriModal modal={modal} setModal={setModal} person={person} />
    </div>
  );
};

export default niri;
