import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import TitleProject from "./../../images/top/title_project.png";

const ProjectSection = () => {
  return (
    <>
      <section id="project_sec" className="sec_padding_base">
        <div className="inner">
          <div className="title_box m-auto">
            <StaticImage
              src="../../../images/top/title_project.png"
              alt=""
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="600"
              data-sal-easing="ease"
              loading="lazy"
            />
          </div>

          <ul className="project_list_box">
            <li className="item">
              <StaticImage
                src="../../../images/niri/niri_image2.jpg"
                alt=""
                className="item_image"
              />
              <div className="item_box">
                <h4 className="item_title">【メインステージ】ニーリの旅</h4>
                <p className="item_text">
                  宮古島のアーティストと子ども達によるエンタメ創出プロジェクト！
                </p>
                <div className="base_button project_button">
                  <Link to="/niri">詳しくみる</Link>
                </div>
              </div>
            </li>
            <li className="item">
              <StaticImage
                src="../../../images/top/project_virtual.jpg"
                alt=""
                className="item_image"
              />
              <div className="item_box">
                <h4 className="item_title">バーチャル同時開催</h4>
                <p className="item_text">
                  人気ゲーム「マインクラフト」でJTAドームを再現！？
                </p>
                <div className="base_button project_button">
                  <Link to="/virtualevent">詳しくみる</Link>
                </div>
              </div>
            </li>
            {/* <li className="item">
              <StaticImage src={ProjectCoin} alt="" className="item_image" />
              <div className="item_box">
                <h4 className="item_title">地域コイン活用</h4>
                <p className="item_text">
                  デジタルコインを通じて宮古島の"新たな価値"を探る！
                </p>
                <div className="base_button project_button">
                  <a onClick={() => alert("現在ページを作成中です。")}>
                    詳しくみる
                  </a>
                </div>
              </div>
            </li> */}
            <li className="item">
              <StaticImage
                src="../../../images/top/pcoject_activity.jpg"
                alt=""
                className="item_image"
              />
              <div className="item_box">
                <h4 className="item_title">学生制作アクティビティ</h4>
                <p className="item_text">
                  「アイデアを形に」オリジナルアクティビティを制作！
                </p>
                <div className="base_button project_button">
                  <Link to="/activity">詳しくみる</Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ProjectSection;
