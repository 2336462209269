import React from "react";
import TitleContact from "../../../images/top/title_contact.png";
import PrivacyModal from "../../parts/PrivacyModal";

const ContactSection = ({ privacyPolicy, setPrivacyPolicy }) => {
  return (
    <>
      <section id="contact_sec" className="sec_padding_base">
        <div className="inner">
          <div className="title_box m-auto">
            <img
              src={TitleContact}
              alt=""
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="600"
              data-sal-easing="ease"
            />
          </div>

          <form
            name="contact"
            method="POST"
            data-netlify="true"
            className="contact_form"
            action="/thanks"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div>
              <label for="name">お問い合わせカテゴリ</label>
              <select name="category">
                <option value="">選択してください</option>
                <option value="協賛の申込・相談">協賛の申込・相談</option>
                <option value="その他のお問い合わせ">
                  その他のお問い合わせ
                </option>
              </select>
            </div>
            <div>
              <label for="name">
                お名前<span>必須</span>
              </label>
              <input name="name" type="text" required placeholder="山田 太郎" />
            </div>
            <div>
              <label for="email">
                メールアドレス<span>必須</span>
              </label>
              <input
                name="email"
                type="email"
                required
                placeholder="xxxxx@example.com"
              />
            </div>
            <div>
              <label for="tel">お電話番号</label>
              <input name="tel" type="tel" placeholder="000-0000-0000" />
            </div>
            <div>
              <label for="company">会社名</label>
              <input name="company" type="company" placeholder="◯◯株式会社" />
            </div>
            <div>
              <label for="content">お問い合わせ内容</label>
              <textarea
                name="content"
                rows="10"
                required
                placeholder="お問い合わせ内容をご記入ください"
              ></textarea>
            </div>
            <p className="contact_comment">
              <a onClick={() => setPrivacyPolicy(true)}>プライバシーポリシー</a>
              に同意のうえ
              <br />
              お問い合わせください
            </p>
            <button type="submit" className="base_button contact_button">
              送信する
            </button>
          </form>
        </div>
      </section>
      <PrivacyModal
        privacyPolicy={privacyPolicy}
        setPrivacyPolicy={setPrivacyPolicy}
      />
    </>
  );
};

export default ContactSection;
